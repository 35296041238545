import { useDefaultDomain } from '@/controllers/subDomain/subDomain.hooks/useDefaultDomain';

export const useFAQPagesGeneric = () => {
  const isDefaultDomain = useDefaultDomain();

  const pages = ['faq-generic-content'];

  if (isDefaultDomain) {
    pages.push('faq-generic-content-ua');
  } else {
    pages.push('faq-generic-content-international');
  }

  return pages;
};
