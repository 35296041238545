import React, { FC, useMemo } from 'react';
import speechBalloonImage from '@/images/generated/icons.emoji.speechBalloon.s3Key';
import briefcaseImage from '@/images/generated/icons.emoji.briefCase.s3Key';
import fireImage from '@/images/generated/icons.emoji.fire.s3Key';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { CourseHeroToggle } from '@/components/courseLanding/CourseHeroToggle/CourseHeroToggle';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Button } from '@/components/ui/Button';
import { COURSE_BLOCKS } from '@/components/courseLanding/CoursePage/CoursePageContent.constants';
import { BenefitsList } from '@/components/landing/BenefitsList';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import styles from './HomePageCoverSection.module.scss';

interface Props {
  title: string;
  ctaText?: string;
  ctaHref?: string;
}

export const HomePageCoverSection: FC<Props> = (props) => {
  const {
    title,
    ctaText,
    ctaHref,
  } = props;

  const { t } = useTranslation([
    I18N_CODES.home,
    I18N_CODES.common,
    I18N_CODES.cta,
  ]);

  const formatter = useNumberFormatter();

  const items = useMemo(() => [
    {
      id: 1,
      title: t(`${I18N_CODES.home}:benefit_employed_students_title`, {
        employedCount: formatter.customFormat(
          Number(t(`${I18N_CODES.common}:employed_students_count`)),
        ),
      }),
      icon: briefcaseImage,
    },
    {
      id: 2,
      title: t(`${I18N_CODES.home}:benefit_mentor_support_title`),
      icon: speechBalloonImage,
    },
    {
      id: 3,
      title: t(`${I18N_CODES.home}:benefit_practice_title`),
      icon: fireImage,
    },
  ], [formatter, t]);

  return (
    <section
      className={cn(styles.wrapper, 'position-relative grid-container')}
      data-animated-section="true"
    >
      <h1
        className={cn(
          typography.landingH1,
          styles.title,
        )}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />

      <div className={cn(styles.toggle, 'mb-56')}>
        <CourseHeroToggle
          title={t(`${I18N_CODES.home}:home_hero_toggle_text`)}
        />
      </div>

      <div className='flex-container align-center mb-56'>
        <Button
          data-qa='hero-section-button'
          mode={Button.mode.BrandPrimary}
          size={Button.size.Large}
          text={ctaText || t(`${I18N_CODES.cta}:home_hero_cta_button`)}
          href={ctaHref || `#${COURSE_BLOCKS.allCourses}`}
        />
      </div>

      <BenefitsList items={items} collideOnSmallScreen />

      <div className={styles.background} />
    </section>
  );
};
