import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconSupportHelp: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.1935 10.2735C13.8268 8.82683 13.8268 7.16683 13.1935 5.72683L11.3668 6.5535C11.7668 7.4735 11.7668 8.52016 11.3735 9.44683L13.1935 10.2735ZM10.2802 2.80683C9.56204 2.49353 8.78699 2.33181 8.0035 2.33181C7.22001 2.33181 6.44495 2.49353 5.72683 2.80683L6.5535 4.62683C7.48016 4.2335 8.52683 4.2335 9.4535 4.6335L10.2802 2.80683ZM2.80683 5.72016C2.49352 6.43945 2.33181 7.2156 2.33181 8.00016C2.33181 8.78473 2.49352 9.56087 2.80683 10.2802L4.6335 9.44683C4.2335 8.52683 4.2335 7.4735 4.6335 6.54683L2.80683 5.72016ZM5.72683 13.1935C6.445 13.5073 7.22045 13.6688 8.0042 13.6676C8.78794 13.6665 9.56292 13.5028 10.2802 13.1868L9.4535 11.3668C8.99689 11.5641 8.50492 11.6665 8.00751 11.6676C7.51011 11.6688 7.01767 11.5687 6.56016 11.3735L5.72683 13.1935ZM8.00016 1.3335C8.87564 1.3335 9.74255 1.50593 10.5514 1.84097C11.3602 2.176 12.0952 2.66706 12.7142 3.28612C13.3333 3.90517 13.8243 4.6401 14.1594 5.44894C14.4944 6.25778 14.6668 7.12468 14.6668 8.00016C14.6668 9.76827 13.9645 11.464 12.7142 12.7142C11.464 13.9645 9.76827 14.6668 8.00016 14.6668C7.12468 14.6668 6.25778 14.4944 5.44894 14.1594C4.6401 13.8243 3.90517 13.3333 3.28612 12.7142C2.03588 11.464 1.3335 9.76827 1.3335 8.00016C1.3335 6.23205 2.03588 4.53636 3.28612 3.28612C4.53636 2.03588 6.23205 1.3335 8.00016 1.3335ZM8.00016 5.3335C7.29292 5.3335 6.61464 5.61445 6.11454 6.11454C5.61445 6.61464 5.3335 7.29292 5.3335 8.00016C5.3335 8.70741 5.61445 9.38568 6.11454 9.88578C6.61464 10.3859 7.29292 10.6668 8.00016 10.6668C8.70741 10.6668 9.38568 10.3859 9.88578 9.88578C10.3859 9.38568 10.6668 8.70741 10.6668 8.00016C10.6668 7.29292 10.3859 6.61464 9.88578 6.11454C9.38568 5.61445 8.70741 5.3335 8.00016 5.3335Z" fill="currentColor" />
  </BaseIcon>
);
