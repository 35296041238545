import React, { memo } from 'react';
import { cn } from '@/lib';
import { IconEmojiCalendar } from '@/components/ui/IconEmojies/IconEmojiCalendar';
import { I18N_CODES } from '@/lib/constants/general';
import { CourseHeroSticker, StickerColorMode, StickerSide } from '@/components/courseLanding/CourseHeroSticker';
import { typography } from '@/components/ui/typography';
import { IconEmojiOneOClock } from '@/components/ui/IconEmojies/IconEmojiOneOClock';
import { useTranslation } from '@/middleware/i18n';
import {
  StudyingFormatBenefit,
} from '@/components/homepage-landing/StudyingFormatsSection/StudyingFormatsSection.typedefs';
import {
  StudyingFormatsBenefitsList,
} from '@/components/homepage-landing/StudyingFormatsSection/components/StudyingFormatsBenefitsList';
import styles from '../StudyingFormatsSection.module.scss';

type Props = {
  postpaidBenefits: StudyingFormatBenefit[];
  prepaidBenefits: StudyingFormatBenefit[];
};

export const StudyingFormats = memo<Props>((props) => {
  const {
    postpaidBenefits,
    prepaidBenefits,
  } = props;

  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.cta]);

  return (
    <div className={styles.formatsWrapper} data-qa="studying-format-section">
      <div className='flex-container flex-dir-column' data-qa="full-time-format">
        <div className={cn(styles.iconRow, 'mb-32')}>
          <IconEmojiCalendar
            className={cn(styles.iconCalendar, styles.iconCircle)}
            alt={t(`${I18N_CODES.home}:fulltime_format_title`)}
          />

          <div className={cn(styles.stickerContainerTop, 'show-for-large')}>
            <CourseHeroSticker
              title={t(`${I18N_CODES.cta}:course_hero_benefit_2`)}
              colorMode={StickerColorMode.Green}
              width='220px'
              originSide={StickerSide.Right}
              markSide={StickerSide.Left}
              rotation={{
                large: 8,
                medium: 4,
                small: 4,
              }}
            />
          </div>
        </div>

        <h3 className={cn(typography.landingH3, 'mb-16')}>
          {t(`${I18N_CODES.home}:fulltime_format_title`)}
        </h3>

        <ul className={cn(styles.benefitsList, 'medium-mb-24')}>
          <StudyingFormatsBenefitsList benefits={postpaidBenefits} />
        </ul>

        <div className={cn(styles.stickerContainerBottom, 'hide-for-large', 'mb-8')}>
          <CourseHeroSticker
            title={t(`${I18N_CODES.cta}:course_hero_benefit_2`)}
            colorMode={StickerColorMode.Green}
            width='220px'
            originSide={StickerSide.Left}
            markSide={StickerSide.Left}
            rotation={{
              large: 14,
              medium: 4,
              small: 4,
            }}
          />
        </div>
      </div>

      <div className={styles.divider}>
        <div className={cn(
          typography.platformTextSmall,
          styles.circle,
        )}
        >
          {t(`${I18N_CODES.home}:or_divider`)}
        </div>
      </div>

      <div className='flex-container flex-dir-column' data-qa="flex-time-format">
        <div className={cn(styles.iconRow, 'mb-32')}>
          <IconEmojiOneOClock
            className={cn(styles.iconWatch, styles.iconCircle)}
            alt={t(`${I18N_CODES.home}:parttime_format_title`)}
          />

          <div className={cn(styles.stickerContainerTop, 'show-for-large')}>
            <CourseHeroSticker
              title={t(`${I18N_CODES.cta}:course_hero_benefit_3`)}
              colorMode={StickerColorMode.Purple}
              width='220px'
              originSide={StickerSide.Left}
              markSide={StickerSide.Right}
              rotation={{
                large: 8,
                medium: 4,
                small: 4,
              }}
              rotationInverse
            />
          </div>
        </div>

        <h3 className={cn(typography.landingH3, 'mb-16')}>
          {t(`${I18N_CODES.home}:parttime_format_title`)}
        </h3>

        <ul className={cn(styles.benefitsList, 'medium-mb-24')}>
          <StudyingFormatsBenefitsList benefits={prepaidBenefits} />
        </ul>

        <div className={cn(styles.stickerContainerBottom, 'hide-for-large', 'mb-16')}>
          <CourseHeroSticker
            title={t(`${I18N_CODES.cta}:course_hero_benefit_3`)}
            colorMode={StickerColorMode.Purple}
            width='220px'
            originSide={StickerSide.Right}
            markSide={StickerSide.Right}
            rotation={{
              large: 14,
              medium: 6,
              small: 6,
            }}
            rotationInverse
          />
        </div>
      </div>
    </div>
  );
});
