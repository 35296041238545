import { IconCalendar } from '@/components/ui/icons/IconCalendar';
import { IconFilters } from '@/components/ui/icons/IconFilters';
import { IconClockTime } from '@/components/ui/icons/IconClockTime';
import { IconOpen } from '@/components/ui/icons/IconOpen';
import {
  StudyingFormatBenefit,
} from '@/components/homepage-landing/StudyingFormatsSection/StudyingFormatsSection.typedefs';
import { IconCheckCircle } from '@/components/ui/icons/IconCheckCircle';
import { IconMoneyDollarCircleLine } from '@/components/ui/icons/IconMoneyDollarCircleLine';
import { IconSupportHelp } from '@/components/ui/icons/IconSupportHelp';

export const postpaidFormatBenefits: StudyingFormatBenefit[] = [
  {
    Icon: IconCalendar,
    translateCode: 'study_fulltime',
  },
  {
    Icon: IconSupportHelp,
    translateCode: 'mentor_support',
  },
  {
    Icon: IconFilters,
    translateCode: 'pass_course_selection',
  },
];

export const prepaidFormatBenefits: StudyingFormatBenefit[] = [
  {
    Icon: IconClockTime,
    translateCode: 'study_flex',
  },
  {
    Icon: IconSupportHelp,
    translateCode: 'mentor_support',
  },
  {
    Icon: IconOpen,
    translateCode: 'learning_after_payment',
  },
];

export const postpaidFormatBenefitsMergedLanding: StudyingFormatBenefit[] = [
  {
    Icon: IconCheckCircle,
    translateCode: 'study_fulltime_merged',
  },
  {
    Icon: IconCalendar,
    translateCode: 'study_fulltime_schedule_merged',
  },
  {
    Icon: IconFilters,
    translateCode: 'pass_course_selection_merged',
  },
];

export const prepaidFormatBenefitsMergedLanding: StudyingFormatBenefit[] = [
  {
    Icon: IconMoneyDollarCircleLine,
    translateCode: 'study_fixed_price_merged',
  },
  {
    Icon: IconClockTime,
    translateCode: 'study_flex_merged',
  },
  {
    Icon: IconOpen,
    translateCode: 'learning_after_payment_merged',
  },
];
