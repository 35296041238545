import React, { FC } from 'react';
import {
  IconCourseHeroToggle,
} from '@/components/courseLanding/CourseHeroToggle/IconCourseHeroToggle/IconCourseHeroToggle';
import styles from './CourseHeroToggle.module.scss';

interface Props {
  title: string;
}

export const CourseHeroToggle: FC<Props> = ({ title }) => (
  <div className={styles.wrapper}>
    <div
      className={styles.title}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <div className={styles.icon}>
      <IconCourseHeroToggle />
    </div>
  </div>
);
