import React, { FC } from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { StudyingFormats } from '@/components/homepage-landing/StudyingFormatsSection/components/StudyingFormats';
import {
  postpaidFormatBenefits,
  prepaidFormatBenefits,
} from '@/components/homepage-landing/StudyingFormatsSection/StudyingFormatsSection.constants';
import styles from './StudyingFormatsSection.module.scss';

export const StudyingFormatsSection: FC = () => {
  const { t } = useTranslation([I18N_CODES.home, I18N_CODES.cta]);

  return (
    <section className={styles.sectionWrapper}>
      <div className='grid-container'>
        <div className='grid-x grid-margin-x align-center'>
          <div className='cell large-10'>
            <h2 className={cn(typography.landingH2, 'text-center', 'mb-72 small-mb-40')}>
              {t(`${I18N_CODES.home}:formats_of_courses`)}
            </h2>

            <StudyingFormats
              postpaidBenefits={postpaidFormatBenefits}
              prepaidBenefits={prepaidFormatBenefits}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
