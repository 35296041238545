import React, {
  FC, useRef, useState, MouseEvent, useCallback,
} from 'react';
import { cn } from '@/lib/classNames';
import styles from './UltraHover.module.scss';

interface Props {
  className?: string;
}
export const UltraHover: FC<Props> = (props) => {
  const { className, children } = props;
  const [active, setActive] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const updateStyles = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    const { offsetWidth, offsetHeight } = element;
    const { left, top } = element.getBoundingClientRect();
    const itemLeft = left + window.scrollX;
    const itemTop = top + window.scrollY;
    const size = Math.sqrt((offsetWidth ** 2) + (offsetHeight ** 2));

    element.style.setProperty('--width', `${size}px`);
    element.style.setProperty('--height', `${size}px`);

    const overlayX = Math.round(event.pageX - itemLeft) - (size / 2);
    const overlayY = Math.round(event.pageY - itemTop) - (size / 2);

    element.style.setProperty('--top', `${overlayY}px`);
    element.style.setProperty('--left', `${overlayX}px`);
  }, []);

  return (
    <div
      ref={elementRef}
      onMouseEnter={(e) => {
        setActive(true);
        updateStyles(e);
      }}
      onMouseLeave={(e) => {
        setActive(false);
        updateStyles(e);
      }}
      className={cn(
        className,
        styles.wrapper,
        {
          [styles.wrapperActive]: active,
        },
      )}
    >
      {children}
    </div>
  );
};
